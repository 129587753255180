@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navbar-floating {
  margin-top: 5vh;
  width: 100%;
  position: sticky;
  padding: 5vh;
  background-color: #d82c2c50;
  border-radius: 50px;
  backdrop-filter: blur(15px);
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-form {
  font-weight: 700 !important;
}

.headerForm {
  padding: 15px;
  border-radius: 15px !important;
  background-color:#f5f5f5 !important;
  text-align: left;
  color: #ff6900 !important;
  border-radius: 20px;
}


.header-title {
    color: #fbfbfb;
    font-weight: 800;
    font-size: 3rem;
    text-transform: uppercase;
    text-align: left;
}

.header-info-container{
  padding-bottom: 8rem;
}

.desc-form {
  font-weight: 500;
  font-size: 0.9rem;
  color: #242424;
}

.header-arrow {
  display: flex;
  justify-content: center;
  width: 90%;
  position: absolute;
  top: 80vh;
  font-size: 5rem;
}

.header-arrow img {
  animation-name: arrowMove;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

.cardsProtec {
  max-width: 360px!important;
  border:0.2rem solid #e1e1e1!important;
  border-top:0.3rem solid #1e1e1e!important;
  border-radius:15px!important;
  padding:1.3rem!important;
  margin: 0.8rem;
  display: flex;
  align-items: center;
  margin-top: 25px!important;
  transition: ease-out;
  transition-duration: 300ms;
}

.cardsProtec:hover {
  transform: scale(1.1);
  filter: drop-shadow(0 5px 5px #ff690090);
  border-color: #ff6900 !important;
  border-top-color: #1e1e1e !important;
}

.contactBtn {
  background-color: #25D366;
  border: 0.2rem solid;  
  text-align: center;
  font-size: 1.3rem;
  color: #f5f5f5;
  border-radius: 5rem;
  padding: 0.7rem 5rem;
  margin-top: 40px;
  font-weight: 600;
  text-decoration: none!important;
  animation-name: wppBtn;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: alternate;
  filter: drop-shadow(0 0 5px #25D366);
}

.contactBtn:hover {
  background-color: #f1f1f1;
  border: 0.2rem solid;
  color: #25D366;
  transform: scale(1.4);
}

.headerBtn {
  background-color: #ff6900;
  border: 0.2rem solid #ff6900;
  display: block;
  text-transform: lowercase;
  font-size: 1.3rem;
  border-radius: 15px;
  font-weight: 600;
  text-align: center;
  color: #f5f5f5;
  cursor: pointer;
  padding: 0.4rem;
}

.privacy-text{
  font-size: 0.8rem;
  margin-top: 1rem;
  font-weight: 600;
  color: #242424;
}

.headerBtn:hover {
  border: 0.2rem solid #e1e1e1;
  background-color: #e1e1e1;
  color: #ff6900;
}

.headerBtn:focus{
  border: 0.2rem solid #e1e1e1;
  background-color: #e1e1e1;
  color: #ff6900;
}

.headerBtn:active{
  border: 0.2rem solid #e1e1e1;
  background-color: #e1e1e1;
  color: #ff6900;
}

.form-label {
  font-weight: 600;
  margin-top: 1rem;
  color: #ff6900;
}

.icon-span {
  background-color: #ff6900;
  border-radius: 50%;
  padding: 20px 20px;
  display: inline-block;
}

.line-detail {
  width: 100%;
}

.line-detail img {
  width: 100%;
}

.contact-col{
  padding-top: 2rem;
}

.section3-bg {
  position: absolute;
}

.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25D366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circles-holder {
  display: flex;
  justify-content: center;
  color: #f1f1f1;
  padding-bottom: 15vh;
  transition: ease-in-out;
  transition-duration: 200ms;
}

.circles-holder div{
  max-height: 20vh;
  filter: drop-shadow(0 5px 5px #0002);
}

.circles-holder span {
  transition: ease-out;
  transition-duration: 300ms;
}

.circles-holder span:hover{
  filter: drop-shadow(0 0 5px #ff6900);
  transform: scale(1.1);
}

.whatsapp-float:hover {
  color: #f5f5f5;
  font-size: 2.5rem;
}

@media screen and (max-width: 1350px){
  .title-form{
    font-size: 1.4rem;
  }

  .form-label{
    margin-top: 0.5rem;
  }
}

@media screen and (min-width: 451px){
  .hiddenDesktop {
    display: none;
  }
}

@media screen and (max-width: 751px){
  .cardsProtec {
    max-width: 100% !important;
  }
  .header-info-container{
    padding-bottom: 4rem;
  }

  .header-title {
    text-align: center;
  }

  h1 {
    font-size: 2rem!important;
  }

  .headerLogo {
    text-align: center!important;
  }

  .headerLogo img {
    margin-top: 50px;
    max-width: 250px!important;
  }

  .headerText {
    padding-top: 50px!important;
  }

  .headerContainer {
    padding-bottom: 5vh;
  }

  .hiddenMobile {
    display: none;
  }
  .contact-col{
    padding-top: 4rem;
  }
  .circles-holder{
    padding-bottom: 45vh;
  }
  .circles-holder div {
    margin-bottom: 45px;
  }

  .contactBtn {
    display: block;
    padding: 10px;
  }

  .contactBtn:hover {
    transform: scale(1);
  }
}

@keyframes arrowMove {
  from {transform: scale(0.4);}
  to {transform: scale(0.6);}
}

@keyframes wppBtn {
  from {border-color: #25D366;}
  to {border-color: #f1f1f1;}
}